import axios from "../utils/requests.js"
import base from './base.js'
import Qs from 'qs'
import store from '../store/index.js'
const api = {
    /**
     * 登录功能
     */
    login(params){
        return axios.post(base.baseURL + base.login, params)
    },
    register(params){
        return axios.post(base.baseURL + base.register, params)
    },
    download(params){
        return axios.post(base.baseURL + base.download, params,
            {
                headers:{
                    'Content-Type': 'application/json',
                    'uid': store.state.user.id,
                    'token': store.state.user.token
                },
                timeout: 10000,
            }
        )
    },
    get_video_fmt(params){
        return axios.get(
            base.baseURL + base.get_video_fmt, 
            {
                params:params,
                paramsSerializer:function(params){
                    return Qs.stringify(params, {arrayFormat:'indices'})
                },
                timeout: 8000,
                headers:{
                    'uid': store.state.user.id,
                    'token': store.state.user.token
                }
            }
            )
    },
    get_user_info(username){
        return axios({
            method: 'get',
            url:base.baseURL + base.get_user_info + "?username="+username,
        })
    },
    get_site_config(){
        return axios.get(base.baseURL + base.get_site_config)
    },
    get_product_list(){
        return axios.get(base.baseURL + base.get_product_list)
    },
    get_payconfig_list(){
        return axios.get(base.baseURL + base.get_payconfig_list)
    },
    get_order_list_by_username(uid){
        return axios.get(base.baseURL + base.get_order_list_by_username + "?username="+uid)
    },
    get_member_record_list_by_username(uid){
        return axios.get(base.baseURL + base.get_member_record_list_by_username + "?uid="+uid)
    },
    create_order(params){
        return axios.post(base.baseURL + base.create_order, params, {
            headers:{
                'uid': store.state.user.id,
                'token': store.state.user.token
            }
        })
    },
    get_order_url(params){
        return axios.post(base.baseURL + base.get_order_url, params)
    },
    get_batchdownload_fmt(params){
        return axios.get(base.baseURL + base.batchDownload + "?"+params ,
            {
                timeout: 10000,
                headers:{
                    'uid': store.state.user.id,
                    'token': store.state.user.token
                }
            })
    },
    get_home_helper_list(){
        return axios.get(base.baseURL + base.get_helper_list +"?path=/home")
    },
    get_batch_helper_list(){
        return axios.get(base.baseURL + base.get_helper_list +"?path=/batch")
    }
}

export default api