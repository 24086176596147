<template>
    <div>
      <el-row class="row_box">
        <el-col :span="15" :offset="2">
          <div class="title" @click="toHome">{{site_config.name}}</div>
        </el-col>
        <el-col :span="3">
          <el-menu :default-active="activeIndex" mode="horizontal" @select="handleSelect"
            background-color="#1abc9c" text-color="#fff" active-text-color="#ffd04b">
            <el-menu-item index="download">单个下载</el-menu-item>
            <el-menu-item index="batchDownload">批量下载</el-menu-item>
          </el-menu>
        </el-col>
        <el-col :span="2">
          <el-dropdown @command="handleDownBoxCommand">
            <span class="el-dropdown-link" v-show="this.$store.getters.isAuthenticated" @click="toMyPage">
              欢迎您 {{this.$store.getters.username}}
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <span class="el-dropdown-link" v-show="!this.$store.getters.isAuthenticated" @click="toLogin">
              去登录
            </span>
            <el-dropdown-menu slot="dropdown" v-show="this.$store.getters.isAuthenticated">
                <el-dropdown-item command="logout">退出登录</el-dropdown-item> 
            </el-dropdown-menu>
          </el-dropdown>
        </el-col>
      </el-row>
    </div>
  </template>
  
  <script>
  import { mapState } from "vuex";
  import api from '../../api/index.js'
  export default {
    data() {
      return {
        activeIndex: 'home',
        current_user: {},
        site_config: {},
      };
    },
    name: 'Header',
    methods: {
      handleSelect(key, keyPath) {
        if (key == 'download') {
          this.$router.push('/home');
        } else if (key == 'batchDownload') {
          this.$router.push('/batchDownload');
        }
      },
      getSiteConfig(){
            api.get_site_config().then(res => {
                this.site_config = res.data.data
                console.log(res.data.data)
            })
        },
      handleDownBoxCommand(command) {
        if (command === 'logout') {
          this.logout();
        }
      },
      toMyPage() {
        this.$router.push('/myPage');
      },
      toLogin() {
        this.$router.push('/welcome');
      },
      logout() {
        console.log(sessionStorage.getItem('token'));
        sessionStorage.removeItem('token');
        this.$message({
          type: 'success',
          message: '退出成功'
        });
        this.$store.commit('setUsername', '');
        this.$store.commit('setAuthentication', false);
        this.$store.commit('setUser', {})
        this.$router.push('/welcome');
      },
      toHome() {
        this.$router.push('/home');
      }
    },
    computed: {
      ...mapState({
        username: (state) => state.username,
      }),
    },
    watch: {
            $route(to,from) {
                this.getSiteConfig()
                if (to.name == 'Download') {
                    this.activeIndex = 'home'
                } else if(to.name == 'BatchDownload') {
                    this.activeIndex = 'batchDownload'
                } else {
                    this.activeIndex = ''
                }
            }
        },
  };
  </script>
  
  <style scoped>
  .row_box {
    background-color: #1abc9c;
  }
  
  .title {
    letter-spacing: 2px;
    font-size: 25px;
    color: #F6F6F6;
    float: left;
    font-weight: 600;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    line-height: 61px;
  }
  
  .el-dropdown-link {
    cursor: pointer;
    color: #fff;
    font-size: 15px;
    line-height: 61px;
  }
  
  .el-icon-arrow-down {
    font-size: 12px;
  }
  </style>
  