/**
 * 存放所有的API接口地址
 */

const base = {
    baseURL:'/api',        // 服务器地址
    login:'/user/login/',                    // 登录
    register:'/user/users/',                // 注册
    download: '/download/download/',        // 单个下载
    get_video_fmt: '/download/download/',    // 获得url信息
    batchDownload: '/download/downloads/',  // 批量下载
    get_user_info: '/user/info/',           // 获取用户信息
    get_site_config: '/siteconfig/siteconfig/', // 获取网站配置信息
    get_product_list: '/product/products/', // 获取商品列表
    get_payconfig_list: '/payconfig/payconfigs/', // 获取支付配置列表
    get_order_list_by_username: '/order/order/username/', // 获取订单列表
    get_member_record_list_by_username: '/member/member/', // 获取会员记录列表
    create_order: '/order/order/', // 创建订单
    get_order_url: '/order/alipay/url/', // 获得订单url
    get_helper_list: '/helper/helpers/' // 获取帮助信息列表
}

export default base