import VueRouter from "vue-router";
import Vue from "vue";
import store from '../store'
Vue.use(VueRouter);

// 页面刷新的时候，重新赋值token
if(sessionStorage.getItem('token')){
    store.commit('setToken',sessionStorage.getItem('token'))
}

const routes = [{
        path: '/',
        redirect: '/home'
    },{
        path: '/welcome',
        name: 'Welcome',
        meta: { show: true },
        component: () => import('@/pages/welcome'),
    },{
        path: '/home',
        name: 'Home',
        component: () => import('@/pages/home'),
    },{
        path: '/book',
        name: 'Book',
        component: () => import('@/pages/book'),
    },{
        path: '/batchDownload',
        name: 'batchDownload',
        component: () => import('@/pages/batchDownload'),
    },{
        path: '/myPage',
        name: 'MyPage',
        component: () => import('@/pages/myPage/myPage')
    },{
        path: '/member',
        name: 'Member',
        component:() => import('@/pages/member/member')
    }
]

const router = new VueRouter({
    routes
})

export default router

router.afterEach(async (to, from, next) =>{
    try {
          window._hmt = window._hmt || []
          window._hmt.push(['_trackPageview', to.fullPath])
        } catch (e) {
          console.log('tongError', e)
      }
})