<template>
    <div class="footer">
        <span class="contact">
            <span class="contact-item">qq：{{ word.qq }}</span>
            <span class="contact-item">微信：{{ word.wechat }}</span>
        </span>
        <span class="description">{{word.description}}</span>      
    </div>
</template>

<script>
import api from '../../api/index.js'
export default {
    name: 'Footer',
    data() {
        return {
            word: {},
            wordList: [
                '悟已往之不谏，知来者之可追。实迷途其未远，觉今是而昨非。',
                '半山腰总是拥挤的，你要去山顶看看。',
                '只有对自己不将就，自己才会变得更优秀。',
                '拒绝摆烂，卷起来。',
                '每一种热爱，都值得全力以赴。',
                '人生如逆旅，我亦是行人。',
                '生而无畏，人类的最高品质便是勇气。',
                '少年心怀乌托邦，心仍向阳肆生长。',
                '平静的大海培养不出优秀的水手。',
                '长风破浪会有时，直挂云帆济沧海。',
            ],
        }
    },
    methods: {
        getWord() {
            this.word = this.wordList[Math.floor(Math.random() * this.wordList.length)];
        },
        getSiteConfig(){
            api.get_site_config().then(res => {
                this.word = res.data.data
            })
        }
    },
    watch: {
        $route() {
            this.getSiteConfig()
        }
    },
}
</script>

<style scoped>
    .footer {
        position: fixed;
        bottom: 0;
        text-align: center;
        line-height: 50px;
        font-size: 16px;
        width: 100%;
        background-color: #1abc9c;
        height: 50px;
        color: #fff;
    }
    .contact{
        position: absolute;
        left: 0;
    }
    .contact-item{
        margin-left: 20px;
    }
</style>