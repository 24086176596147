import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element'
import './assets/css/reset.css'
import MetaInfo from 'vue-meta-info'
import 'animate.css'
import Axios from 'axios'
Vue.config.productionTip = false

Vue.use(MetaInfo)
Axios.defaults.headers.common['Authentication-Token'] = store.state.token; 
// 添加请求拦截器
Axios.interceptors.request.use(config => {
  // 在发送请求之前做些什么
  //判断是否存在token，如果存在将每个页面header都添加token
  if(store.state.token){
    config.headers.common['Authentication-Token']=store.state.token
  }
 
  return config;
}, error => {
  // 对请求错误做些什么
  return Promise.reject(error);
});
 
// http response 拦截器
Axios.interceptors.response.use(
response => {
 
  return response;
},
error => {
 
if (error.response) {
  switch (error.response.status) {
    case 401:
    this.$store.commit('del_token');
    router.replace({
      path: '/login',
      query: {redirect: router.currentRoute.fullPath}//登录成功后跳入浏览的当前页面
    })
    break
  }
}
return Promise.reject(error.response.data)
});

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')