let bookList = [{
    cover: require('../assets/images/1.png'),
    bookName: '三体',
    author: '刘慈欣',
    price: 42,
    type: '文学',
    description: '《三体》的幻想源于经典物理中的三体问题，即三个体积质量相当的天体，在远离其它星系以致其它星系的引力影响可忽略不计的情况下，三个天体在互相引力 的作用下互相围绕运行，其运行轨迹将产生不可预测的混沌。很多年来，数学家们一直希望能建立三体乃至多体问题的数学模型，可遗憾的是，得到的结果仅仅是三 体问题在非限制条件下的不可解。'
}, {
    cover: require('../assets/images/2.png'),
    bookName: '云边有个小卖部',
    author: '张嘉佳',
    price: 28.7,
    type: '文学',
    description: '1. 张嘉佳全新作品。畅销千万现象级作品《从你的全世界路过》后，暌违五年，写给离开我们的人，写给陪伴我们的人，写给每个人心中的山和海。2. 精美手绘插画。陪你度过云边镇的日与夜。3. 惊喜彩蛋。张嘉佳作词单曲《刘十三》，带你来到书中现场。4. 希望和悲伤，都是一缕光。总有一天，我们会再相遇。'
}, {
    cover: require('../assets/images/3.png'),
    bookName: '我们仨',
    author: '杨绛',
    price: 29.8,
    type: '文学',
    description: '《我们仨（精）》是钱钟书夫人杨绛撰写的家庭生活回忆录。1998年，钱钟书逝世，而他和杨绛专享的女儿钱瑗已于此前（1997年）先他们而去。在人生的伴侣离去四年后，杨绛在92岁高龄的时候用心记述了他们这个特殊家庭63年的风风雨雨、点点滴滴，结成回忆录《我们仨》。'
}, {
    cover: require('../assets/images/4.png'),
    bookName: '教养在生活的细节里',
    author: '洪兰',
    price: 39,
    type: '教育',
    description: '《教养在生活的细节里》洪兰与蔡颖卿对父母、老师关注的27个典型问题进行了温柔而智慧的对谈，我们从中可以看到：教养不是方法论，而是生活本身；大人与孩子相处的分分秒秒里都是对孩子潜移默化的教育。关注细节，用平等、尊重、温柔、爱、智慧与孩子相处，彼此关照，共同成长。'
}, {
    cover: require('../assets/images/5.png'),
    bookName: '教育常识',
    author: '李政涛',
    price: 36,
    type: '教育',
    description: '教育常识的原点，就是人性常识，也就是对人的天性的认识。衡量一位教师和教育理论研究者是否进入了智慧的境界，不仅要看他有没有与教育有关的实践智慧和思想智慧，更要看他有没有直捣人心、洞察人性的智慧，有没有形成自己独特的对人性的理解。'
}, {
    cover: require('../assets/images/6.png'),
    bookName: '博赞儿童思维导图',
    author: '东尼·博赞',
    price: 60,
    type: '教育',
    description: ' 描述孩子成长过程中、在理想状态下会喜欢的东西，帮助孩子完全开发自己思维、身体和精神的潜能。只要给予孩子们合适的激励和正确的培养环境，每一个孩子都可以成为非凡的天才。而重要的是，他们一定会成长为一个内心丰富而幸福的人。这是一本能够带给所有孩子成功与幸福的书，也是给予家长的教育指南！'
}, {
    cover: require('../assets/images/7.png'),
    bookName: '花卉动物速写描摹本',
    author: '孔祥彦',
    price: 50,
    type: '绘画',
    description: '零基础画画入门新手自学教程书籍线描花卉临摹手绘初学者学绘画教材铅笔画素描速写画稿美院名师范画，具有各种花卉图片供给临摹和观赏。'
}, {
    cover: require('../assets/images/8.png'),
    bookName: 'A Bigger Book',
    author: 'Hockney',
    price: 95,
    type: '绘画',
    description: '这本SUMO出版的《A Bigger Book》是对David Hockney艺术的一次宏大的视觉考察。在这本书中，Hockney回顾了他60多年的作品，从他十几岁在艺术学校的日子，到他最近大量的肖像画、iPad绘画和约克郡风景画系列。Hockney的作品从来没有以如此大的范围、如此大的投入、如此惊人的规模出版。'
}, {
    cover: require('../assets/images/9.png'),
    bookName: '绘画之道',
    author: 'Susan Herbert',
    price: 91,
    type: '绘画',
    description: '猫咪以沉思，沉思的表情完美地重塑了印象派大师的伟大作品，无论是漫步在克劳德·莫奈的野生罂粟丛中，坐在玛丽·卡萨特的歌剧中，还是在奥古斯特·雷诺阿的Bougival享受周日舞蹈。他们可能会陷入沉闷，绝望，低俗或母亲，资产阶级或知识分子的迷人或沉迷，但它们总是印象派的猫，自发地，毫无准备地被照相机抓住。印象派猫是艺术猫系列中的绝妙补充，一定会吸引任何猫迷。'
}, {
    cover: require('../assets/images/10.png'),
    bookName: '摄影的艺术',
    author: '杨建飞',
    price: 49.9,
    type: '摄影',
    description: '这部著作被认为是最具可读性、最易理解和最全面的摄影教科书。现在，它已经更新了数码摄影的内容。这本书有超过100幅黑白和彩色摄影的插图，还有各种图表，可以帮助初学者、进阶者乃至高级摄影师找到自己的摄影表达方式。Bruce没有故作高深，也没有以高人一等的姿态进行论述，而是以清晰、简明、易懂的方式介绍了数码和传统摄影的创作技术。他还超越了技术层面，深入地讨论了有关摄影的哲学、表达方式以及创意的内容，这往往是其他摄影书避而不谈的内容。'
}, {
    cover: require('../assets/images/11.png'),
    bookName: '摄影基础教程',
    author: '杨建飞',
    price: 108,
    type: '摄影',
    description: '数码单反手机摄影书 人物风景静物艺术旅行婚礼儿童成长记录摄影构图取景光线后期处理摄影学院级入门自学教材，本教材着重于艺术角度的阐释。此外，随着近年来 数码摄影的崛起，本教材也顺应了这一趋势，偏重在数码摄影方面，偶尔涉及传统的胶片摄影，也是为了做比较之用。编写过程中，参考了很多前辈的相关著述，也选取了许多著名摄影师的作品作为图例。'
}, {
    cover: require('../assets/images/12.png'),
    bookName: '今日摄影',
    author: '马克·德登',
    price: 45.8,
    type: '摄影',
    description: '作为一名拥有多重学术背景（作者系艺术学士，艺术史与理论硕士以及摄影研究博士）的作家和艺术家，马克·德登（Mark Durden）对摄影写作的兴趣并不仅仅局限于上述某一类的讨论之上， 而是以其丰富的知识结构作为依托，将当代艺术摄影、摄影理论和哲学的复杂话题融汇贯通于摄影史的书写之中。《今日摄影》全书以十一个主题章节作为框架，包含了街拍、肖像、风景和纪实摄影等相关主题，重现了摄影在每个类别中的非线性发展过程，同时通过介绍各个摄影类别之间的联系，描述了这一时期重要艺术家和摄影师多样的实践方法和形式。'
}, {
    cover: require('../assets/images/13.png'),
    bookName: '中国通史',
    author: '中国通史',
    price: 128,
    type: '历史',
    description: '傅乐成《中国通史》上起旧石器时代，下讫1912年清帝退位（台湾版还包括《民国的忧患》和《中国的现代化》两章，大陆版则无），凡六十余万言。文字浅近平易，不做繁征博引，叙说清晰，见解持正，数千年中国史事之此伏彼起，重要节点，前因后果，俱在目前。*初由台湾大中国图书公司于1960年出版，1968年增订，1977年再次增订。自增订以来，已出版近四十次，在台湾出版的中国通史之中，被大家公认为很好的一部作品。'
}, {
    cover: require('../assets/images/14.png'),
    bookName: '春秋战国',
    author: '梁启超',
    price: 56,
    type: '历史',
    description: '《春秋战国:争霸九州》内容简介：春秋时代，大国争霸，小国争胜，强者存，弱者亡。善良和邪恶明争暗斗，英雄和美女惺惺相惜。作者提炼古今史料，以写实的手法，生动地描绘了齐桓公、宋襄公、晋文公、秦穆公、楚庄王五位春秋霸主励精图治，广招贤才，争霸天下的雄略，塑造了管仲、鲍叔牙、百里奚、孙叔敖等人满腹谋略，勤政爱民的光辉形象，同时也刻画了卫姬、隗后、弄玉、樊姬等一系列宫廷女性的不同命运……《春秋战国（上卷）争霸九州》以其宏大的结构，深刻的内涵，艺术地再现了这个被视为中国历史上最具活力、最伟大的时代。'
}, {
    cover: require('../assets/images/15.png'),
    bookName: '中国史纲',
    author: '张荫麟',
    price: 99,
    type: '历史',
    description: '文辞优美，能给读者带来阅读享受的历史作品：别人写历史都引用史籍，张荫麟却不囿于史籍，他甚至把《诗经》《楚辞》《论语》词句用的神出鬼没，给一部严肃的学术作品，披上了华丽丽的文学外衣，使得这部《中国史纲》华彩奕奕。'
}, {
    cover: require('../assets/images/16.png'),
    bookName: '格局',
    author: '何权峰',
    price: 36,
    type: '哲学',
    description: '格局指一个人的眼界、胸襟、胆识等心理要素的内在布局。再大的烙饼也大不过烙它的锅。只会盯着树皮里的虫子不放的鸟儿是不可能飞到白云之上的，只有眼里和心中装满了山河天地的雄鹰才能自由地在天地间翱翔！'
}, {
    cover: require('../assets/images/17.png'),
    bookName: '了凡四训',
    author: '袁了凡',
    price: 24,
    type: '哲学',
    description: '《了凡四训》为明代袁了凡所著训子善书，阐明“命自我立，福自己求”的思想，指出一切祸福休咎皆自当人掌握，行善则积福，作恶则招祸；并现身说法，结合儒释道三家思想以自身经历体会阐明此理，鼓励向善立身，慎独立品，自求多福，远避祸殃。该书自明末以来流行甚广，影响较大，此白话绘图本的出版当有助于阅读了解，于个人品德修养与世道人心改善或许不无小补。'
}, {
    cover: require('../assets/images/18.png'),
    bookName: '宇宙',
    author: '卡尔·萨根',
    price: 68,
    type: '科学',
    description: ' 宇宙一直是这个样子，过去如此，将来也如此。对宇宙不够有力的思考唤醒我们对周围的感觉——脊柱传来的一阵刺痛，捕捉到的一个声音，好似来自遥远的记忆，我们常常有一种从高空坠落的微弱感觉。我们知道我们正接近那个最终的秘密。'
}, {
    cover: require('../assets/images/19.png'),
    bookName: '认识星座',
    author: '阿斯达',
    price: 268,
    type: '科学',
    description: '星座是占星学中不可或缺的组成部分之一，由天上的恒星组合而成。星座是占星学中不可或缺的组成部分之一，由天上的恒星组合而成。在西方文化中，星座的地位几乎相当于生肖在我国的地位，就像我们利用自己的生辰测算自己一生的运数一样，西方世界则利用星座来测算吉凶。”'
}, {
    cover: require('../assets/images/20.png'),
    bookName: '学会表达',
    author: '丁艳丽',
    price: 88,
    type: '语言',
    description: '卡耐基魅力口才与说话技巧提高情商语言能力高情商训练图书籍，不能完全表达言者的本意，要透过语言揣摩本质，也要提高表达能力，能让自己表达得更准确完备。自从明确地提出：“要会表达”。'
}, {
    cover: require('../assets/images/21.png'),
    bookName: '高情商聊天术',
    author: '张超',
    price: 36.8,
    type: '语言',
    description: '让你一开口就能说到对方心里去 有礼有节才能说话周全口才说话技巧书籍畅销书 职场生活口才训练书好好说话情商，99%的人际问题出在不会说话上，本书教你做一个真正会聊天的人!听说两种能力训练+3大场景模拟对话+10年表达经验锤炼。影响，说服，沟通，谈判，谈笑间搞定！'
}, {
    cover: require('../assets/images/22.png'),
    bookName: '口才三绝',
    author: '歌诗达',
    price: 158,
    type: '语言',
    description: '本书是一剂向大家介绍提高口才能力和说话技巧的良方！主要从口才必备的三个方面来阐述提升口才的技巧：第一篇主要介绍了敏捷的思维是措辞的关键；第二篇主要阐述了赞美的话要说到点子上；第三篇与读者一起分享了不同场景下与不同人沟通、交流、说话的技巧等。 本书口才的基础入手，将科学性与技巧性、理论指导与实际场景融于一体。只要你精心阅读，坚持训练，本书定会逐渐引导你走向捷径，最后轻松带你步入成功口才的殿堂。'
}, {
    cover: require('../assets/images/23.png'),
    bookName: '孙子兵法',
    author: '孙武',
    price: 49.8,
    type: '军事',
    description: '《孙子兵法》是中国古代汉族军事文化遗产中的璀璨瑰宝，汉族优秀传统文化的重要组成部分，其内容博大精深，思想精邃富赡，逻辑缜密严谨，是古代汉族军事思想精华的集中体现。作者为春秋时祖籍齐国乐安的吴国将军孙武。《孙子兵法》被称为镇国之宝，在中国被奉为兵家经典。诞生至今已有2500年历史，历代都有研究。李世民说“观诸兵书，无出孙武”。兵法是谋略，谋略不是小花招，而是大战略、大智慧。如今，孙子兵法已经走向世界。它也被翻译成多种语言，在世界军事史上也具有重要的地位。'
}, {
    cover: require('../assets/images/24.png'),
    bookName: '世界战列舰全史',
    author: '罗伯特',
    price: 68,
    type: '军事',
    description: '作为曾经辉煌的日不落帝国，英国依靠强大的皇家海军控制着整个海洋。进入铁甲蒸汽时代之后，拥有大口径主炮、厚重装甲和高功率发动机的战列舰成为英国海上力量的象征。在战列舰建造领域，英国不断推出具有跨时代意义的作品，其中包括“君权”级、“无畏”号、“伊丽莎白女王”级等，这些强大的战舰成为引领世界海军发展的风向标。'
}, {
    cover: require('../assets/images/25.png'),
    bookName: '本草纲目',
    author: '李时珍',
    price: 48,
    type: '医药',
    description: ' 《本草纲目》是李时珍所著的集中国16世纪前本草学大成的中医典籍，属《四库全书》子部医家类。该典籍于明代万历六年定稿，万历二十四年在南京正式刊行。《本草纲目》共有52卷，载有药物1892种，其中载有新药374种，收集医方11096个，约190万字，分为16部、60类。'
}, {
    cover: require('../assets/images/26.png'),
    bookName: '奇效偏方',
    author: '李春深',
    price: 68,
    type: '医药',
    description: '古人说用药如用兵，如果把那些出于名医之手和载入各种医典之中的医药方剂视为正规部队就可以把偏方看作是游击队，同样为病人解除痛苦。偏方是原生态，是现代方剂和新生药物取之不尽的源泉，是祖国医药宝库中光彩夺目的明珠。'
}, {
    cover: require('../assets/images/27.png'),
    bookName: '中医急诊学',
    author: '姜良铎',
    price: 78,
    type: '医药',
    description: '是新中国成立以来第yi次对中医急诊学科发展进行的全面、系统总结。 《中医急诊学(精)》由上篇总论、下篇各论和附篇三部分组成。首次梳理了中医急诊科学术发展史，系统阐述了中医急诊科基本理论、临证思路和学习方法，介绍急诊科病症的诊疗方法、临床经验和研究新进展'
}, ]

export default bookList