let categoryList = [
    '全部分类',
    '文学',
    '教育',
    '绘画',
    '摄影',
    '历史',
    '哲学',
    '科学',
    '语言',
    '军事',
    '医药',
]

export default categoryList;